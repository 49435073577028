/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
		  var s = $(".nav-primary");
			var pos = s.position();
			$(window).scroll(function() {
				var windowpos = $(window).scrollTop();
				if (windowpos >= pos.top) {
					s.addClass("sticky");
				} else {
					s.removeClass("sticky");
				}
			});

			// hide our element on page load
			 $('.alignright').css('opacity', 0);

			 $('.alignright').waypoint(function() {
				  $('.alignright').addClass('fadeInRight');
			 }, { offset: '100%' });

			 $('.service-1').css('opacity', 0);

			 $('.service-1').waypoint(function() {
				  $('.service-1').addClass('fadeInLeft');
			 }, { offset: '100%' });

			 $('.service-2').css('opacity', 0);

			 $('.service-2').waypoint(function() {
				  $('.service-2').addClass('fadeInUp');
			 }, { offset: '75%' });

			 $('.service-3').css('opacity', 0);

			 $('.service-3').waypoint(function() {
				  $('.service-3').addClass('fadeInRight');
			 }, { offset: '50%' });

			 $('.faq-1').css('opacity', 0);

			$('.faq-1').waypoint(function() {
				 $('.faq-1').addClass('fadeInLeft');
			}, { offset: '100%' });

			$('.faq-2').css('opacity', 0);

			$('.faq-2').waypoint(function() {
				 $('.faq-2').addClass('fadeInUpBig');
			}, { offset: '75%' });

			$('.faq-3').css('opacity', 0);

			$('.faq-3').waypoint(function() {
				 $('.faq-3').addClass('fadeInRight');
         }, { offset: '50%' });
             
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'appointment_2': {
      init: function() {
        // JavaScript to be fired on the about us page
		window.addEventListener("load", function() {
                // Get reference to iframe contentWindow

                var f = document.getElementById("pluginFrame").contentWindow;

                // configure data for plug-in.
                // type:        message type should always be 'ekyros'
                // apiKey:      Identifies Call Center
                // regKeys:     array that identifies 1 or more office locations for a pregnancy center.
                // themeColor:  specify a primary color used by the plug-in.  This is (optional) param, leave blank for default 'blue' color.
                //              accepts hex value or named color.

                var data = { type: "ekyros", apiKey: "1c2afaa0-4cd3-4f76-9462-dbd00417442d", regKeys: ["4a629de3-db5e-4b59-aa24-e11ebdddc669"], themeColor: "#bb374e" };

                // post message to plug-in running in iframe with config data.
                // Param1: Config data for Plug-in
                // Param2: Secuity requirement.  Must be origin (domain) used in iframe SRC attribute.

                f.postMessage(data, "https://pluslinkplugin.ekyros.com");
             });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
